.generate-params-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px;
  flex: 1;
  z-index: 1;
  border: 2px dashed #D9D9D9;
  border-radius: 10px;
  .loading-backdrop {
    width: 100%;
    height: 100%;
    background-color: #0e006b5d;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    border-radius: 10px;
}
  .generate-params-prompt-select-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .generate-ai-content {
    .generate-ai-prompt-checkbox-container {
      display: flex;
      flex-direction: column;
      .generate-ai-prompt-checkbox-active{
        background-color: #eee3fd !important;
        border: 2px dashed #2f14e5 !important;
      }
      .generate-ai-prompt-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 8px;
        border: 2px dashed #888;
        background-color: #F9F9F9;
        .generate-ai-prompt-checkbox-label {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .generate-ai-errors {
      color: red;
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }
  }



  .body-interactions-input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    min-height: 50px;
    max-height: 230px;
    border-radius: 35px;
    padding: 10px;
    background-color: rgb(239, 238, 243);

    .add-file {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }

    .input-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: left;
      align-items: left;

      .added-file {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        margin-left: 20px;
      }

      .query-input {
        // disble resize
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 0;
        margin-top: 15px;
        padding-bottom: 0;
        font-size: 17px;
        font-weight: 500;
        min-height: 48px;
        max-height: 100px;
        width: 100%;
        margin-left: 15px;
      }

      .query-input:focus {
        outline: none;
      }
    }

    .button-section {

      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;

      .ask-btn {
        background-color: transparent;
        border: none;
        box-shadow: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;

        .icon {
          color: white;
          width: 40px;
          height: 40px;
        }
      }
    }

    .ask-input {
      border-radius: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      flex: 9;
    }

    .ask-button {
      flex: 1;
      border-radius: 20px;
      color: white;
    }
  }
}
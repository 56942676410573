.react-flow__node {
  border: 2px solid #61dafb;
  padding: 10px;
  width: 150px;
  font-size: 12px;
  color: #333;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.workflow-graph-client .react-flow__handle  {
  cursor: grab !important;
}
.react-flow__edge {
  stroke: #61dafb;
  stroke-width: 2px;
}

.react-flow__controls {
  bottom: 2px;
  right: 2px;
  width: 300px;
}
.react-flow {
  position: absolute !important;
}

.react-flow__background {
  position: static !important;
}

.context-menu {
  background: white;
  border-style: solid;
  box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
  position: absolute;
  z-index: 10;
}

.context-menu button {
  border: none;
  display: block;
  padding: 0.5em;
  text-align: left;
  width: 100%;
  border-radius: 0%;
}

.context-menu button:hover {
  background: grey;
}


.modal-content-preview-file {
  background: white;
  width: 80%;
  max-width: 800px;
  position: relative;
}
.btn-add-file{
  height: 40px;
  background-color: var(--primary-color-medium);
  color: white;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02), 0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12), 0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
  border-radius: 4px;
  border: none;
  position: absolute;
  bottom: 2%;
  left: 5%;
  width: 90% !important;
margin-top: 25px;
}
.lookup-img-file{
  position: absolute;
  right: 100px;
  top: 42%;
  transform: translateY(-50%);
}
.error-message{
  color: red;
  font-size: 13px;
  margin-bottom: 5px;
}
.lookup-img-arabic-file{
right:85%;
left: 40px;
}
.dropdown-list-files{
  position: absolute;
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 95%;
  background-color: white;
  z-index: 5;
  border: 1px solid gainsboro;
  border-radius: 8px;
  word-break: break-all;
  top: 130px;
  li{
      padding-right: 12px;
      font-size: 13px;
      text-overflow: ellipsis;
  }
}
.disabled-btn{
  background-color: #F5F6F6 !important;
  color: #E0E0E1 !important;
}
.modal-content-preview-file h2 {
  margin-top: 0;
}
.document-pdf-viewer>iframe,
.document-docx-viewer>iframe,.document-image-viewer {
  max-height:80vh;
  margin-bottom: 5px;
}
.document-image-viewer>img{
  display: block;
  height: 400px !important;
  margin-top: 3px;
}
.add-new-file-container {
  position: relative;
  display: inline-block;
  
  input[type="file"] {
    position: absolute;
    opacity: 0 !important;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .file-upload-link {
    color: var(--primary-color-medium);
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
    margin: auto;
  }
}


.ai-text-container {
  overflow-y: auto;
  background-color: var(--secondary-color-lighter);
  border: 2px dashed #888;
  transition: all 0.8s ease;
  opacity: 0.8;
  padding: 20px;
  max-height: 79vh;
  border-radius: 12px;
  white-space: pre-line;
  text-align: justify;
}

.ai-text {
  font-weight: bold;
  font-size: 13px;
  text-align: justify;
  word-wrap: break-word;
}
.text-right{
  text-align: right !important;
}
.text-left{
  text-align: left !important;
}
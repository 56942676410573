:root {
    --primary-color: #2f14e5;
    --primary-color-darker: #130093;
    --primary-color-medium: #2f14e5;
    --primary-color-light: #452aff;
    --primary-color-lighter: #9585ff;
    --secondary-color: #300962;
    --secondary-color-darker: #420c88;
    --secondary-color-medium: #7014e5;
    --secondary-color-light: #893aee;
    --secondary-color-lighter: #eee3fd;
    --ai-informative-darkest: #1f7284;
    --ai-informative-medium: #30b1cd;
    --ai-informative-clear: #b5e3ee;
    --ai-error-darkest: #960d0d;
    --ai-error-medium: #e51414;
    --ai-error-clear: #f8abab;
    --ai-warning-darkest: #6c4205;
    --ai-warning-medium: #f98543;
    --ai-warning-clear: #fdebd0;
    --ai-success-darkest: #0e5823;
    --ai-success-medium: #45e271;
    --ai-success-clear: #d3f8dd;
    --neutral-black: #131316;
    --neutral-darker: #1e1e24;
    --neutral-dark: #363540;
    --neutral-medium: #5e5c70;
    --neutral-light: #d3d2da;
    --neutral-extra-light: #f3f2f5;
}

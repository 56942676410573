.reglement-modal{
  .reglement-modal-content{
    height: 50vh;
    overflow-y: auto;
    margin-bottom: 70px;
  }
  .suggestions{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
  }
  .suggest-add{
    cursor: pointer;
    background-color: #d6d1f4;
    flex: 1;
    max-width: 20%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    font-size: 15px;
    border: 1px solid #AA9FF4;
    color: #707274;
  }
  .suggest-card{
    flex: 1;
    max-width: 20%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    background-color: white;
    font-size: 15px;
    border: 1px solid #AA9FF4;
    color: #707274;
    position: relative;
  }
  .delete-suggest-card{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    color: red;
    border: 1px solid red;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-title{
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
  .hyper-params-section{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 10px;
  }
  .hyper-params-col{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .input-field{
      width: 100% !important;
    }
  }
  .hyper-param-container{
    height: 130px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    .input-label{
      color: black !important;
    }
  }
  .compression-params-header{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    .title{
      font-size: 20px;
    font-weight: bold;
    }
    .form-check{
      margin: 0;

    }
  }
}
.config-section {
  margin-bottom: 24px;
  padding: 10px;

  .section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
  }

  .config-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 30vh;
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 5px;
  }

  .config-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 8px 12px;

    span {
      flex: 1;
      font-size: 14px;
      color: #333;
    }

    .status-actions,
    .category-actions {
      display: flex;
      gap: 8px;
    }

    .status-button,
    .category-button {
      background: #5c4646;
      border: none;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background: #d5d5d5;
      }

      &.remove {
        background: #ee3132;
        color: white;

        &:hover {
          background: #d12b2b;
        }
      }
    }
  }

  .add-item {
    display: flex;
    gap: 10px;

    input {
      flex: 1;
      padding: 6px 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 14px;
    }

    .add-button {
      padding: 6px 12px;
      font-size: 16px;
      background-color: #2f14e5;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background-color: #1e0fd1;
      }
    }
  }
}

.scoring {
  display: flex;
  flex-direction: column;
  width: 100%;

  .category {
      display: flex;
      justify-content: space-between; 
      align-items: center;          
      width: 99%;
      padding: 4px;
      padding-right: 10px;
      padding-left: 10px;
      border-radius: 8px;
      margin-bottom: 10px;
      font-size: 1rem; 

      span {
          &:first-child {
              font-weight: bold;
          }

          &:last-child {
              font-weight: bold;
              font-size: 1.1rem; 
          }
      }

      &.red {
          background-color: #f8d7da;
          color: #842029;
      }

      &.yellow {
          background-color: #fff3cd;
          color: #856404;

      }

      &.green {
          background-color: #d4edda;
          color: #155724;
      }

      &.grey {
          background-color: #e7e7e7;
          color: #383d41;
      }
  }
}
.edit-contract-compliance-clause {
  border-radius: 12px;
  box-shadow: 1px 1px 1px 1px #e5e9f2, -1px -1px 1px 1px #e5e9f2;
  &.score-1 {
    border: 2px solid #a9f0c3; 
    &.clause-selected {
      background-color: #effcf3; 
    }
    }
    &.score-2,
    &.score-3 {
      border: 2px solid #faeaa8; 
      &.clause-selected {
        background-color: #fffdf5; 
      }
    }
  
    &.score-4 {
      border: 2px solid #ffa8a8; 
      &.clause-selected {
        background-color: #fcecec; 
      }
    }
    &.score-5 {
      border: 2px solid #e7e7e7;
      &.clause-selected {
        background-color: #f5f5f5; 
      }
    }
  &.clause-selected {
     
      transition: all 0.8s ease;
      opacity: 0.9;
      padding: 10px;
      max-height: 79vh;
      overflow-y: auto;
      overflow-x: hidden;
  }

  /* Webkit (Chrome, Safari) */
  ::-webkit-scrollbar {
      width: 6px;
  }

  ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
      background-color: #f0f0f0;
  }

  /* Firefox */
  ::-moz-scrollbar {
      width: 6px;
  }

  ::-moz-scrollbar-track {
      background: #f0f0f0;
  }

  ::-moz-scrollbar-thumb {
      background: #888;
      border-radius: 6px;
  }

  .selectable {
      cursor: pointer !important;
  }

  .fade-enter {
      opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
  }

  .fade-exit {
      opacity: 1;
  }

  .fade-exit.fade-exit-active {
      opacity: 0.01;
      transition: opacity 500ms ease-in;
  }

  .scrollable-clause {
      max-height: 80vh;
      overflow-y: auto;
  }



  .edit-contract-clause-comment {
      font-size: 22px;
      color: #ffffff;
      margin-bottom: 10px;
      padding: 5px;
      max-height: 150px;
      margin-top: 10px;
  }

  .speech-bubble {
      position: relative;
      max-width: 20em;
      background-color: #7014e5;
      padding: 1.125em 1.5em;
      font-size: 12px;
      border-radius: 1rem;
      box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
  }

  .input-comment {
      background-color: #7014e5;
      padding: 3px;
      margin: 0;
      font-size: 12px;
      border: none;
      color: #ffffff;
  }

  .input-comment:focus {
      outline: none;
  }

  .scrollable-clause::-webkit-scrollbar {
      width: 8px;
  }

  .scrollable-clause::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 4px;
  }

  .scrollable-clause::-webkit-scrollbar-thumb {
      background-color: var(--neutral-light);
      border-radius: 4px;
  }

  .scrollable-clause::-webkit-scrollbar-thumb:hover {
      background-color: var(--neutral-medium);
  }

  .edit-contract-clause-header-icons {
      height: 40px;
      cursor: pointer;
      // min-width: 100%;
      margin-left: 0% !important;
      float: right;
      right: 0;
  }

  .edit-contract-clause-header {
      font-size: 20px;
      color: #556581;
      display: flex;
      justify-content: space-between;
      align-items: flex-end; /* Align children to the bottom */
      .view-details { 
        font-size: 10px;
        font-weight: normal;
      }
    

  }

  .edit-contract-clause-header-icon:first-of-type {
      margin-right: 13px;
  }

  .triangle {
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-bottom: 13px solid #7014e5;
      /* You can set the color to your preference */
      position: absolute;
      top: -12px;
      left: 17px;
  }
  .edit-contract-clause-header-icons-arabic{
      min-width: 90px;
      position:relative;
      right: 88%;
      height: 40px;
  }

  .comment-text {
      max-height: 70px;
      overflow-y: auto;
      word-wrap: break-word;
  }
}
.badge {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  color: #fff;

  &.badge-green {
    background-color: #d4edda;
    color: #155724;
  }

  &.badge-yellow {
    background-color: #fff3cd;
    color: #856404;
  }

  &.badge-red {
    background-color: #f8d7da;
    color: #842029;
  }

  &.badge-gray {
    background-color: #d5d4d4;
    color: #556581;
  }
}
.compliance-description-container{
  border-radius: 10px;
  border: 2px solid #fff3cd;
  padding: 4px;
  padding-right: 10px;
  padding-left: 10px;
  .compliance-description-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
.compliance-button{
  width: 100%;
  height: 2rem;
  border-radius: 10px;
  
}
.clause-compliance-second-container {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.skeleton-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
  }
  .reorder-modal{
    .clause-list{
      overflow-y: auto;
      max-height: 60vh;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .modal-buttons{
      display: flex;
      gap: 10px;
    }
    .cancel-button{
      background: #E5E9F2;
      color: #000;
      text-align: center !important;
      font-size: 15px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      text-transform: uppercase !important;
      flex: 1;
      letter-spacing: 0.5px;
      border-radius: 6px !important;
    }
    .confirm-order-button{
      background: #2F14E5!important;
      color: #fff !important;
      text-align: center !important;
      font-size: 15px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      text-transform: uppercase !important;
      flex: 1;
      letter-spacing: 0.5px;
      border-radius: 6px !important;
    }
  }
  .button-grp{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    .compliance-grp-button{
      flex: 1;
      height: 2rem;
      border-radius: 10px;
    }
  }
  .skeleton-header,
  .skeleton-body,
  .skeleton-line {
    background: linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
    );
    background-size: 200% 100%;
    animation: shimmer 3s infinite;
    border-radius: 4px;
  }
  
  .skeleton-header {
    height: 20px;
    width: 30%;
  }
  .skeleton-line {
    height: 20px;
    width: 100%;
  }
  .skeleton-body {
    height: 100px;
    width: 100%;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  .summary-category {
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s, border-color 0.3s;

  &.red {
    background-color: #ffe5e5;
  }
  
  &.yellow {
    background-color: #fff3e0;
  }
  
  &.green {
    background-color: #e8f5e9;
  }
  
  &.grey {
    background-color: #f5f5f5;
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:last-child {
      font-weight: bold;
    }

    .chevron {
      font-size: 1rem;
      transition: transform 0.3s ease;
    }

    &.expanded .chevron {
      transform: rotate(180deg); // Rotates the chevron upward
    }
  }

  .clauses-list {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;

    &.expanded {
      max-height: 200px; // Adjust to the maximum expected height
      overflow-y: auto;
    }
  }
}
.return-to-summary {
  display: inline-block; 
  padding: 10px 20px;
  color: #2F14E5;
  border: 2px solid #2F14E5; 
  background-color: white; 
  border-radius: 8px; 
  text-align: center; 
  cursor: pointer; 
  font-size: 16px;
  font-weight: bold; 
  transition: all 0.3s ease; 
  margin-bottom: 10px;
  margin-right: 10px;
  &:hover {
    background-color: #f9f8ff; 
  }
}
.view-details-btn {
  border-radius: 6px !important;
  background: #2F14E5!important;
  color: #fff !important;
  text-align: center !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  width: 100% !important;
  letter-spacing: 0.5px;
}
.manual-signature {
  padding: 20px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &__filename {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    text-align: center;
  }
  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__step {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__step-number {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color-medium);
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
  }

  &__step-text {
    font-size: 14px;
    color: #333;
    padding-top: 1rem;
  }

  &__button {
    min-width: 200px; /* Prevents full width */
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &--primary {
      background: var(--primary-color-medium);
      color: white;
      border: none;

      &:hover {
        background: #0056b3;
      }
    }

    &--outline {
      background: white;
      color: var(--primary-color-medium);
      border: 1px solid #007bff;

      &:hover {
        background: #f0f8ff;
      }
    }
  }
}
.modal-footer-signature {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Push buttons to opposite corners */
  align-items: center;

}
/* New specific classes for the buttons and their descriptions */
.manual-signature__steps {
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.manual-signature__button-primary,
.manual-signature__button-secondary {
  min-width: 200px;
  padding: 5px;
  font-size: 25px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .manual-signature__description {
    font-size: 14px;
    color: white;
    margin-top: 10px;
  }
  .manual-signature__description-outline {
    font-size: 14px;
    color: #777;
    margin-top: 10px;
  }
}

.manual-signature__button-primary {
  background: var(--primary-color-medium);
  color: white;
  border: none;

  &:hover {
    background: #0056b3;
  }
}

.manual-signature__button-secondary {
  background: #f8f9fa;
  color: var(--primary-color-medium);
  border: 1px solid var(--primary-color-medium);

  &:hover {
    background: #e9ecef;
  }
}
.manual-signature__button:disabled {
  background: var(--primary-color-darker);
  cursor: not-allowed;
  pointer-events: none;
  
} 
.cancel-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 16px;
  margin-top: 10px; 
  display: block; 
  width: 150px; 
  cursor: pointer;
  margin-left: auto;
  margin-right: auto; 

  &:hover {
    background-color: darkred;
  }

  &:disabled {
    background-color: lightcoral;
    cursor: not-allowed;
  }
}


.Task-container {
  width: 100%;
  min-height:100vh;
  display: flex;
  align-items: flex-start;
  padding: 0 0 32px 20px; /* Changed: removed right padding */
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 0;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  padding-bottom: 16px;
  
  /* Add these properties to ensure proper scrolling */
  box-sizing: border-box;
  position: relative;
}

.Task-container::after {
  content: "";
  flex: 0 0 20px; /* Create a spacer element that won't shrink */
  height: 1px;
  display: block;
}

.Task-column {
  flex: 0 0 320px; /* Fixed width that doesn't grow or shrink */
  display: flex;
  flex-direction: column;
  gap: 0;
}

/* Ensure the wrapper for the Task container has proper sizing */
/* Add this to your parent container if needed */
.task-page-wrapper {
  width: 100%;
  overflow: hidden; /* Prevent double scrollbars */
}

/* Style scrollbar for Webkit browsers */
.Task-container::-webkit-scrollbar {
  height: 8px;
}

.Task-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.Task-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Make sure the other task-related classes keep their styling */
.Task-droppable {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  align-items: center;
  padding: 20px 0;
}

.Task-column-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  background: white;
  border-radius: 8px;
  color: #555;
  font-weight: 500;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.edit-task-button {
 padding-left: 10px;
 padding-right: 10px;
 margin-left: 25px;
 margin-right: 25px;
}
.Task-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
  padding: 10px;
  width: 100%;
  background: white;
  color: #555;
  font-weight: 500;
  font-size: 15px;
  opacity: 0.9;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.Task-add-icon {
  color: #555;
}
.task-card-container {
  width: 100%;
  cursor: grab;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 16px;
}
.task-card {
  width: 100%;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  align-items: flex-start;
  padding: 16px;
  min-width: 250px;
}

.task-card img {
  width: 100%;
  height: 170px;
  border-radius: 8px;
}

.task-tags {
  display: flex;
  align-items: center;
  gap: 8px;
}

.task-tag {
  padding: 2px 10px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 6px;
}

.task-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.task-title {
  font-size: 15.5px;
  font-weight: 500;
  color: #555;
}

.task-description { 
  font-size: 13.5px;
  color: #888;
  white-space: nowrap;    
  overflow: hidden;        
  text-overflow: ellipsis;  
  max-width: 250px;       
}

.task-createdAt {
  font-size: 10px;
  color: #888;
  margin-top: 5px;
}
.task-updatedAt {
  font-size: 10px;
  color: #888;
}

.task-divider {
  width: 100%;
  border: 1px solid #ECECED;
}

.task-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-time {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  color: #666;
}

.task-priority {
  display: flex;
  align-items: center;
  justify-content: center;
}

.priority-icon {
  font-size: 18px;
}
.priority-critical {
  color: red;
}
.priority-high {
  color: orange;
}

.priority-medium {
  color: yellow;
}

.priority-low {
  color: green;
}
.avatar-container {
  display: flex;
  align-items: center;
}

.avatar-user {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #2F14E5;
  color: white;
  font-size: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.avatar-user:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.avatar-users {
  width: 30px;
  height: 30px;
  background-color: #2F14E5;
  color: #ffffff;
  font-size: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px; /* Superposition légère */
  cursor: pointer;
  border: 1px solid #ccc;
}

.more-users {
  background-color: #ccc;
  color: white;
  font-size: 12px;
}
.task-creator {
  display: flex;
  align-items: center;
  gap: 6px;
}

.creator-label {
  font-size: 12px;
  color: #666;
}
.task-menu {
  position: absolute;
  top: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 120px;
  z-index: 20;
  display: flex;
  flex-direction: column;
}

.task-menu-item {
  padding: 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f1f1f1;
  }
}
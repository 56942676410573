.usecase-item-modal{
  min-width: 250px;
  height: 150px;
  word-wrap: break-word;
  white-space: normal;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top:10px;
  padding-top:10px ;
  color: #363540 !important;
  text-overflow: ellipsis;
  background-size:cover;
  background-repeat: no-repeat;
  cursor: pointer;
  border: 1px solid #E5E9F2;
  font-size: 16px;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 2px 8px -1px #e5e9f2;
}
.usecase-item-modal:hover{
  border: 2px solid #2F14E5;

}
.usecases-container-modal{
  display: flex;
  overflow-x: scroll;
  margin-bottom: 10px;
  margin-left: 25px;
  margin-top: -15px;
  
}
.topics-container-modal {
  display: flex;
  white-space: nowrap;
  margin-bottom: 20px;
  margin-left: 25px;
  margin-top: 20px;
}
.version-display{
  .subclause-title{
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: black;
  }
  .clause-segmentation{
    margin-top: 10px !important;
    margin-bottom: 20px;
  }
}
/* topics  css*/
.topics-page {
    width: 100%;
}
    
    .topics-page>.title {
        font-size: 36px;
        color: #343541;
    }
    
    .topics-page>.subtitle {
        color: #8094AE;
        font-size: 14px;
        line-height: 23.1px;
        font-weight: lighter;
    }
    
    .topics-container {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        max-width: 170vh;
        margin-bottom: 50px;
        margin-left: 25px;
        margin-top: 20px;
    }
    
    .topics-scroll {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
    .topic-item {
        flex: 1;
        justify-content: center;
        display: flex;
        align-items: center;
        height: 50px;
        word-wrap: break-word;
        white-space: normal;
        margin-right: 20px;
        padding: 50px;
        color: #363540;
        text-overflow: ellipsis;
        background-size:cover;
        background-repeat: no-repeat;
        cursor: pointer;
        border: 1px solid #E5E9F2;
        font-size: 15px;
        font-weight: bold;
        background-color: white;
        border-radius: 10px;
    }
    
    .topic-item.activated {
        color: #2F14E5;
        border: 2px solid #2F14E5;
        background-color: rgb(47, 20, 229, 0.1);
    }
    .usecases-container{
        display: flex;
        overflow-y: auto;
        max-height: 60vh;
        max-width: 100%;
        margin-bottom: 15px;
        margin-left: 25px;
        margin-top: -15px;
        padding-right: 20vh;
    }
    .usecases-scroll{
        display: flex;
        flex-wrap: wrap;
    }
    .usecase-item{
        width: 250px;
        height: 160px;
        word-wrap: break-word;
        white-space: normal;
        margin-right: 20px;
        margin-bottom: 20px;
        margin-top: 30px;
        padding-top:30px ;
        color: #363540 !important;
        text-overflow: ellipsis;
        background-size:cover;
        background-repeat: no-repeat;
        cursor: pointer;
        border: 1px solid #E5E9F2;
        font-size: 16px;
        border-radius: 20px;
        text-align: center;
        font-weight: bold;
        box-shadow: 0px 2px 8px -1px #e5e9f2;
    }
    .usecase-item:hover{
        border: 2px solid #2F14E5;
    
    }
    .selected-usecase{
        border: 2px solid #2F14E5;
        background-color: rgb(47, 20, 229, 0.1);
    }
    
    .usecasetext{
        color: #363540;
        margin: auto;
        margin-top: 18px;
        font-size: 14px;
        font-weight: 700;
        width: 80%;
    }
    .selected-textusecase{
        color: #2F14E5;
    }
    .action-calculate-compliance {
        background-color: #2F14E5 !important;
        width: 95% !important;
        box-shadow: none !important;
        color: white;
        border-radius: 10px !important;
        margin: 0;
        font-size: 14px;
        margin-left: 2%;
        height: 50px;
    }
    
    .btn-import{
        width: 100%;
        height: 50px;
        background-color: var(--primary-color-medium);
        color: white;
        font-size: 14px;
        font-weight: 700;
        box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02), 0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12), 0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
        border-radius: 4px;
        border: none;
        position: absolute;
        bottom: 13px;
        left: 18px;
        width: 90% !important;
    }
    .separation-line{
        color: #E5E9F2;
        width: 95%;
        margin-left: 40px;
        margin-bottom: 20px;
        border: 0.5px solid;
    }
    .coming-soon-message{
        background-image: url("../../assets/bg-comingSoon.png");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        width: 101%;
        height: 101.2%;
    }
    .footer-section{
        position: absolute;
        bottom: 20px;
        width: 100%;
    }

.TaskViewModal {
  &-body {
    padding: 24px;
    max-height: 80vh;
    overflow-y: scroll;
  }
  
  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  &-column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  &-section {
    padding-bottom: 16px;
    
    &:not(:last-child) {
      border-bottom: 1px solid #eaeaea;
    }
  }
  
  &-title {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 12px 0;
    color: #333;
  }
  
  &-section-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 12px 0;
    color: #555;
  }
  
  &-status-badge {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 8px;
    background-color: #e3f2fd;
    color: #1565c0;
    
    &[data-status="planned"] {
      background-color: #e3f2fd;
      color: #1565c0;
    }
    
    &[data-status="inProgress"] {
      background-color: #fff8e1;
      color: #ff8f00;
    }
    
    &[data-status="done"] {
      background-color: #e8f5e9;
      color: #2e7d32;
    }
    
    &[data-status="cancelled"] {
      background-color: #ffebee;
      color: #c62828;
    }
  }
  
  &-priority {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #666;
    margin-top: 8px;
  }
  
  &-priority-icon {
    font-size: 18px;
    &.critical {
      color: red;
    }
    &.high {
      color: orange;
    }
    
    &.medium {
      color: yellow;
    }
    
    &.low {
      color: green;
    }
  }
  
  &-comment {
    font-size: 15px;
    line-height: 1.5;
    color: #333;
    margin: 0;
    white-space: pre-line;
  }
  
  &-dates {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  &-date-item {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  &-date-label {
    font-size: 13px;
    color: #666;
  }
  
  &-date-value {
    font-size: 14px;
    color: #333;
  }
  
  &-users {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  &-user {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  &-user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #555;
  }
  
  &-user-info {
    display: flex;
    flex-direction: column;
  }
  
  &-user-name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
  
  &-user-email {
    font-size: 13px;
    color: #666;
  }
  
  &-resources {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  &-resource-badge {
    padding: 4px 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    font-size: 13px;
    color: #555;
  }
  
  &-empty-message {
    font-size: 14px;
    color: #888;
    font-style: italic;
  }
  
  &-activity {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  &-activity-item {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  &-activity-label {
    font-size: 13px;
    color: #666;
  }
  
  &-activity-value {
    font-size: 14px;
    color: #333;
  }
  
  &-edit-button {
    padding: 6px 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
    
    &:hover {
      background-color: #0069d9;
    }
  }
  
  &-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  &-close {
    background-color: #f1f1f1;
    border: none;
    color: #333;
    
    &:hover {
      background-color: #e0e0e0;
    }
  }
}
.previous-pathname,
.current-pathname {
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
    border: 1px solid #E5E9F2;
    padding: 5px 10px;
    border-radius: 10px;
}

.previous-pathname:hover {
    background-color: #f1f1f1;
    border: 1px solid #E5E9F2;
    padding: 5px 10px;
}

.current-pathname {
    color: #2f14e5;
}

.container-paths {
    margin-bottom: 10px;
}

.path-separator {
    font-weight: bold;
    font-size: 13px;
    color: #c4c9d2;
}
.page::before {
    content: "";
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url("../assets/BG.png");
    background-position: calc(100% - 150px) calc(100% - 20px);
    background-repeat: no-repeat;
    background-size: 45%;
}
.page h1 {
    font-weight: 700;
    color: var(--neutral-darker);
    font-size: 20px;
}
.page h1 span {
    font-weight: bold;
}
.page h2 {
    font-weight: 900;
    color: var(--neutral-darker);
}

.page h2 span {
    color: var(--primary-color);
}
.page p {
    font-weight: 400;
    font-size: 16px;
    color: var(--neutral-medium);
    margin-top: 20px;
}
.main-admin-function-content{
    margin-top: 2% !important;
    display: flex;
    align-items: center;
} 
 .main-admin-function-content>div{
    margin-top: 1%;
    height: 230px;
    margin-bottom: 5%;
}
.main-admin-function-content .btn-action-admin{
    position: absolute !important;
    right: 5% !important;
    bottom: 5% !important; 
    width: 90% !important;
}
.btn-action-holder {
    margin-top: auto; /* Push buttons to the bottom */
    display: flex;
    gap: 10px; /* Space between buttons */
    justify-content: center; /* Align buttons horizontally */
}


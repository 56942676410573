.display-block {
  display: block;
}

.display-none {
  display: none;
}

.contractz-lab-export {
  background-color: white;
  opacity: 1 !important;
  z-index: 1050 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  width: 600px;
}

.btn-display {
  height: 50px;
  background-color: var(--primary-color-medium) !important;
  color: white;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02),
    0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12),
    0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
  border-radius: 4px;
  border: none;
  position: absolute;
  bottom: 2%;
  width: 90% !important;
}
.btn-display:hover {
  background-color: var(--primary-color-medium) !important;
}

.modal-backdrop {
  background-color: #0e006b;
  opacity: 0.6 !important;
}

/* comon style modal  */
.modal-title {
  color: #300962;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
}

.modal-header {
  border-bottom: 1px solid #dbdfea !important;
  background-color: #e8e9eb;
  display: flex;
  justify-content: space-between;
}

.modal-body .thead-table {
  font-size: 15.6px;
  font-style: normal;
  border-bottom: 1px solid #dbdfea;
  padding-bottom: 12px;
  padding-top: 20px;
  font-weight: 500;
  line-height: 20.79px;
}

/* update form-control */
.form-control {
  height: fit-content !important;
  width: 170px !important;
  margin-top: 20px !important;
  border-radius: 10px !important;
  padding-left: 12px !important;
}

/* //update btn close of the popup */
.modal-header .btn-close {
  border-radius: 50% !important;
  width: 15px;
  font-size: 10px;
  background-color: #777875 !important;
  border: none;
  height: 15px;
  color: white;
  background-position: center center !important;
  background-image: url("../../assets/close-X.png");
  background-repeat: no-repeat !important;
  margin-right: 10px;
}

.modal-body {
  border-bottom: 0 !important;
  padding: 0px 40px 0px 40px;
  padding-top: 0px !important;
  overflow-y: auto;
}

/* update form-control */
.form-control {
  height: fit-content !important;
  width: 170px !important;
  margin-top: 20px !important;
  border-radius: 10px !important;
  padding-left: 12px !important;
}

input[type="text"] {
  width: 100%;
  height: 48px;
  border: 1px solid var(--neutral-light);
  border-radius: 10px;
}
input[type="color"] {
  background-color: transparent;
}
input[type="number"].range {
  width: 100%;
  height: 24px;
}

select {
  color: var(--primary-color-medium) !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  height: 48px;
}

select:focus {
  border-color: #3f51b5;
}

/* update class */
.modal-footer {
  padding: 0 !important;
  margin: 20px 0 0 0;
  justify-content: flex-start;
  border-top: none;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.modal-backdrop .btn {
  width: 100%;
  height: 50px;
  background-color: var(--primary-color-medium);
  color: white;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02),
    0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12),
    0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
  border-radius: 4px;
  border: none;
}
/* comon css */

.btn-handler {
  border-radius: 50% !important;
  width: 30px !important;
  margin-top: 15px;
  height: 30px !important;
  color: #fff;
  padding: 0;
  background-color: #2f14e5 !important;
}
.btn-handler:hover {
  color: white !important;
}
.edit-table-footer {
  margin-left: 50px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.save-btn {
  border-radius: 800px !important;
  border: 1px solid #2f14e5 !important;
  background: #2f14e5 !important;
  color: #fff;
  text-align: center;
  font-size: 17px !important;
  min-width: 171px !important;
  font-style: normal;
}
/* comon css */
.custom-placeholder:focus::placeholder {
  opacity: 0 !important;
}

.date-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.input-with-icon {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
  pointer-events: none;
}

.date-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--primary-color);
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  cursor: pointer;
}

.date-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem var(--primary-color-darker);
}

.grey-border {
  border: 2px solid #acabba !important;
}

.green-border {
  border: 2px solid var(--ai-success-medium) !important;
}

.react-datepicker__input-container input {
  border: none !important;
  cursor: pointer;
  outline: none !important;
}

.question-modal {
  color: #2b2e31 !important;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 50.6px;
  padding-top: 20px !important;
  padding-bottom: 30px;
  padding-left: 0 !important;
  margin-left: 0 !important;
  width: 80%;
}

.submit-draft {
  border-radius: 15px !important;
  background: #2f14e5 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  width: 300px !important;
  letter-spacing: 0.5px;
}

.cancel-draft {
  border-radius: 10px !important;
  color: #b3b3b3;
  background-color: #f0f0f0 !important;
  border: none !important;
  box-shadow: none !important;
  text-align: center !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  width: 300px !important;
  letter-spacing: 0.5px;
}

/* add new class */
.export-contract-text {
  color: #8094ae;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-top: 20px;
  padding: 0;
}

.word-img {
  background-image: url("../../assets/word.png");
  background-repeat: no-repeat;
  width: 22px;
  height: 20px;
  margin-right: 20px;
  background-size: cover;
}
.json-img {
  background-image: url("../../assets/icons/jsonIcon.jpg");
  background-repeat: no-repeat;
  width: 18px;
  height: 20px;
  margin-right: 20px;
  background-size: cover;
}

.csv-img {
  background-image: url("../../assets/csv.png");
  background-repeat: no-repeat;
  width: 22px;
  height: 20px;
  background-size: cover;
  margin-right: 20px;
}

.export-buttons {
  display: flex;
  flex-direction: row !important;
  margin-top: 10px;
}

.export-btn {
  background-color: transparent !important;
  color: black !important;
  box-shadow: none !important;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 190px !important;
  display: flex;
}

.export-title {
  background: none;
  color: black;
  font-weight: bold;
  margin: 0 !important;
}

.header-export {
  background-color: white;
  border-bottom: none !important;
}

.body-export {
  margin: 0 !important;
}

/* css of beneficial new modal */
.slider-wrapper {
  display: flex;
  width: 350px;
  text-align: center;
  align-items: center;
  margin-top: 70px !important;
  align-self: center;
  margin: auto;
  border-radius: 10px !important;
}

.slider-option {
  flex: 1;
  text-align: center !important;
  cursor: pointer;
  background-color: #e5e9f2;
  transition: background-color 0.3s ease;
  padding: 10px;
  height: 40px;
  color: #b6c6e3;
}

.slider-option:hover {
  background-color: #e0e0e0;
}

.selected-type-beneficial {
  background-color: #2f14e5 !important;
  color: #ffffff;
}

.right {
  border-radius: 0px 10px 10px 0px;
}

.left {
  border-radius: 10px 0px 0px 10px;
}

.label-beneficial {
  color: #344357;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.1px;
}

.container-input-beneficial {
  width: 350px;
}

.container-input-beneficial > input {
  width: 100% !important;
}

.add-beneficial-btn {
  border-radius: 800px !important;
  border: 1px solid #2f14e5 !important;
  background: #2f14e5 !important;
  width: 200px !important;
  height: 43px !important;
  box-shadow: none !important;
}
.content-input-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100px !important;
  padding-bottom: 100px;
}
.content-beneficial {
  min-height: 600px !important;
  height: fit-content !important;
}
.body-beneficial {
  min-height: 60vh !important;
}
.modal-dialog {
  min-width: 65% !important;
  // max-height: 80vh !important;
}

#contractz-lab .modal-content {
  background-color: white;
  opacity: 1 !important;
  z-index: 1050 !important;
  width: 100%;
  // min-height: 70vh;
  overflow-y: auto;
}

.modal-content:focus {
  outline: 3px solid var(--primary-color-medium) !important;
}

.export-modal-specific {
  margin-right: 0% !important;
}
.case-form-labelName {
  color: #333 !important;
  font-size: 15px;
}
.case-form-labelInput > input {
  // width:350px !important;
  height: 40px !important;
}
.form-input-content {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}
.modal-body-pilotage {
  min-height: 60vh !important;
}
.footer-form-pilotage {
}

.btn-action-list-group {
  display: flex !important;
  align-items: center !important;
  width: 75% !important;
  align-self: center;
  margin: auto;
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}
.list-group-modal-content::-webkit-scrollbar {
  width: 8px;
}

.list-group-modal-content::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
  border-radius: 4px;
}

.list-group-modal-content::-webkit-scrollbar-thumb {
  background-color: rgb(204, 202, 205) !important;
  border-radius: 4px;
}

.list-group-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--neutral-medium);
}
.list-group-modal-content {
  min-height: 80vh !important;
}
.custom-color-input {
  color: #333 !important;
  padding: 2% !important;
}
.custom-textarea {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  padding: auto;
  width: 100%;
  height: 58px;
  padding-top: 4%;
  padding-right: 2%;
  overflow-wrap: break-word;
}
.custom-textarea::-webkit-scrollbar {
  width: 8px;
}

.custom-textarea::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
  border-radius: 4px;
}

.custom-textarea::-webkit-scrollbar-thumb {
  background-color: rgb(204, 202, 205) !important;
  border-radius: 4px;
}

.custom-textarea::-webkit-scrollbar-thumb:hover {
  background-color: var(--neutral-medium);
}
.actions-questionUseCase {
  display: flex;
  width: 35%;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 1%;
}
.input-companyName-container {
  position: relative;
}

.lookup-img {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.input-beneficial-container > input,
.input-companyName-container > input {
  width: 100% !important;
}

.company-name-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2000;
  border-radius: 12px;
  border: 1px solid #e5e9f2;
  background: #fff;
  box-shadow: 0px 3px 12px 1px rgba(23, 12, 47, 0.15);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 22.8px;
  padding-left: 4%;
  padding-top: 2%;
}
.add-new-beneficial {
  border-radius: 50% !important;
  width: 30px !important;
  margin-top: 15px;
  height: 30px !important;
  color: #fff;
  font-size: 15px;
  padding: 0;
  background-color: #2f14e5 !important;
}
.audit-workflow-steps {
  background-color: #f5f6fa;
  border-radius: 12px;
  width: 90%;
  margin-left: 5%;
  margin-top: 2%;
  display: flex;
  justify-content: center;
}
.audit-workflow-steps-arabic {
  margin-left: 0;
  margin-right: 5%;
}
.content-steps {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2%;
  padding-top: 2%;
  width: fit-content;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: #7f8fa4 #f5f6fa;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(204, 202, 205);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}
.edit-audit-step-line {
  position: absolute;
  top: 14%;
  height: auto;
  left: -2%;
  width: 85%;
  transform: translateX(70%);
}
.edit-audit-step-line-arabic {
  right: -2%;
  transform: translateX(-70%);
}
.edit-audit-step {
  position: relative;
  font-size: 14px;
}
.audit-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step-name {
  text-align: center;
  width: 150px;
  height: 30px;
}
.types-contract-title {
  font-weight: bold;
  font-size: 15px;
}
.content-levels {
  display: flex;
  flex-direction: column !important;
  width: 40%;
}
.content-levels > label {
  margin-bottom: 15px;
}
.content-levels > select {
  margin-bottom: 15px;
  color: #526484 !important;
  background-color: white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  border-radius: 5px;
  padding-left: 5px;
}
.content-use-case-question {
  min-height: 250px;
}
.action-import-typeLevels {
}
.scroll-table {
  max-height: 400px;
  overflow-y: auto;
}
.modal-typelevels {
  min-height: 70vh !important;
}
.btn-warning {
  background-color: var(--primary-color-medium);
  color: white;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02),
    0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12),
    0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
  border-radius: 4px;
  border: none;
  text-align: center;
  padding: 1%;
  margin-bottom: 2%;
  cursor: pointer;
}
.error-after-import {
  font-size: 13px !important;
}
// css of modal import rules
.modal-import-rules {
  width: fit-content;
  overflow-y: hidden;
  max-height: 580px !important;
}
.content-importRules {
  width: fit-content !important;
  margin-left: -11% !important;
  height: auto !important;
}
.table-import-rules {
  overflow-x: hidden;
  max-height: 400px !important;
}
.action-import-rules {
  width: 100%;
  padding: 15px;
}
.input-popup {
  width: 80% !important;
}

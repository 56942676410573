.actions {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  .ai-icon{
    width: 30px;
    height: 30px;
    position: absolute;
    right:25px;
    [dir="rtl"] & {
      right: auto; 
      left: 25px;  
    }
  }
  .popup {
    position: absolute;
    width: 100px;
    background-color: white;
    
    .contract-card-actions-modal {
      border: 1px solid#1c1d2212;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 100%;
      right: 0;
      padding: 8px;
      background-color: white;
      width: fit-content;
      left: -30px;
    }
  
    .contract-card-actions-modal ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  
    .contract-card-actions-modal li {
      cursor: pointer;
      padding: 5px;
      width: 100%;
      transition: background-color 0.3s ease;
    }
  
    .contract-card-actions-modal li:hover {
      background-color: #f2f2f2;
    }

    li:hover {
      background-color: #F5F5F5;
      cursor: pointer;
    }
  }

  .plus-icon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    border: 1px solid gray;
    box-shadow: none;
    background-color: white;
  }

  .plus-icon:hover {
    background-color: #F5F5F5;
  }
}
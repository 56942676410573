
.TaskModal-button {
  width: 100%;
  height: 36px;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
}

.TaskModal-label {
  color: #333 !important;
  font-size: 13px;
}
.TaskModal-body{
  max-height: 80vh;
  overflow-y: scroll;
  padding: 24px;
  padding-bottom: 0px;
}
.TaskModal-input{
  width: 100%;
  min-width: 150px;
  height: 48px;
  border: 1px solid var(--neutral-light);
  border-radius: 10px;
  padding: 20px;
  text-decoration: none;
}
.TaskModal-input-group{
  margin-bottom: 10px;
}
.TaskModal-select{
  width: 100%;
  min-width: 150px;
  height: 48px;
  border: 1px solid var(--neutral-light);
  border-radius: 10px;
  text-decoration: none;
}
.TaskModal-text-area{
  width: 100%;
  min-height: 300px;
  border: 1px solid var(--neutral-light);
  border-radius: 10px;
  padding: 20px;
  text-decoration: none;
}

.TaskModal-submit {
  width: 100%;
  color: #fff;
  margin-top: 12px;
}


.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  gap: 50px; /* Space between columns */
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Date and Time inputs side by side */
  gap: 10px;
}
.selected-users {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.badge-user {
  color: #333;
  padding: 6px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #ccc;
}

.remove-badge {
  margin-left: 8px;
  cursor: pointer;
}

.remove-badge:hover {
  color: #ee3132;
}
.selected-ressources {
  margin-top: 8px;
}
.document-page-container{
    display: flex;
    flex-direction: row;

.header-useCaseQA{
    font-weight: 900;
    font-size: 23px;
    color: var(--neutral-medium);
}
.document-page-text-section{
    flex: 7;
    padding-left: 22px;
    padding-top: 5px;
    .contract-name{
        font-size: 20px;
    }
}
.document-page-use-case-section{
    flex: 5;
}
.ai-text-container {
    overflow-y: auto;
    background-color: var(--secondary-color-lighter);
    border: 2px dashed #888;
    transition: all 0.8s ease;
    opacity: 0.8;
    padding: 20px;
    max-height: 79vh;
    border-radius: 12px;
    white-space: pre-line;
}

.ai-text {
    font-weight: bold;
    font-size: 14px;
}
}
.submit-btn-doc {
    background-color: #2F14E5 !important;
    width: 100% !important;
    box-shadow: none !important;
    color: white;
    border-radius: 15px !important;
    margin: 0;
    font-size: 14px;
    margin-left: 2%;
    height: 50px;
}
.list-grp-section {
  padding: 20px;
  width: 100%;
    table{
    border-spacing: 0; /* Removes spacing between table cells */
    border-collapse: collapse;
    height: auto;
    border: 1px solid #ccc;
  }
  tbody{
    max-height: 300px;
    overflow-y: hidden;

  }
  td {

    border-bottom: 1px solid #ccc;
  }
  .table-container-groups {
    max-height: 50vh;
    width: 95%;
    overflow-y: auto;
    border: 1px solid #ccc;
    position: relative;
    margin-top: 3%;
    font-size: 15px;
  }
  .react-tags-wrapper{
    input[type=text]{
      padding-left: 0px !important;
    }
  }
  .ReactTags__selected{
    display: flex;
    align-items:flex-start;
    flex-direction: column;
    align-self: center;
    align-items: center !important;
    margin: auto !important;
  }
  .arabic-row{
    .ReactTags__remove{
      right: auto !important;
      left: 24px !important;
    }
  }
  .ReactTags__tag{
    width: 95%;
    margin-top: 3%;
    position: relative;
  }
  .container-users{
    .ReactTags__remove{
      right: auto; 
      left: 50%; 
    }
  }
  .ReactTags__remove{
    position: absolute;
    right: 24px;
    width: 22px;
    height: 22px;
    background-color:#bcb9b9;
    color: white;
    justify-content: center !important;
    border-radius: 50% !important;
    font-size: 15px;
    box-shadow: none !important;
    line-height: 2px !important;
  }
  .ReactTags__tagInput{
    margin-top: 5% !important;
    margin-bottom: 5% !important;
    width: 80%;
    align-items: center;
    align-self: center;
    margin: auto;
  }
  .ReactTags__tagInput>input::placeholder{
    padding-right: 5px !important;
  }
  .fixed-header-table {
    width: 100%;
    border-collapse: collapse;
  }
  .fixed-header-table th,
  .fixed-header-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  .arabic-table th,
  .arabic-table td {
    text-align: right;
  }

  .fixed-header-table thead th {
    position: sticky;
    top: 0;
    background-color: #f1f1f1;
    z-index: 1;
  }
.button-add-new-user{
  margin-top: 3%;
}
  .action-add-user {
    background-color: #2F14E5 ;
    box-shadow: none ;
    color: white;
    border-radius: 27px ;
    width: 220px ;
    margin: 0;
    font-size: 15px;
    height: 50px;
  }
  .delete-user{
    margin-left: 20px;
  }
  .edit-user{
    cursor: pointer;
  }
  .actions-list-groups{
    position: absolute;
    bottom:6%;
  }
  .btn-grp{
    width: 28vh;
    margin: auto;
    padding: 10px !important;
    background-color: var(--primary-color-medium);
    color: white;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02),
      0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12),
      0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
    border-radius: 4px;
    border: none;
    margin-bottom: 20px;
  }
}
  
  

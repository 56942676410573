.chat-with-document-page-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
  .ask-page-header{
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    .reglement-list{
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow-y: auto;
    }
    .reglement-item {
      flex: 1;
      justify-content: center;
      text-align: center;
      display: flex;
      align-items: center;
      height: 50px;
      word-wrap: break-word;
      white-space: normal;
      margin-right: 20px;
      padding: 30px;
      color: #363540;
      text-overflow: ellipsis;
      background-size:cover;
      background-repeat: no-repeat;
      cursor: pointer;
      border: 1px solid #E5E9F2;
      font-size: 15px;
      font-weight: bold;
      background-color: white;
  }
  .reglement-item-active{
    color: #2F14E5 !important;
    border: 2px solid #2F14E5 !important;
    background-color: rgb(47, 20, 229, 0.1) !important;
  }

    .left-section {
      margin-left: 30px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;

      .close {
        border-right: 1px solid #E5E5E5;
        padding-right: 15px;
      }

      .title {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .name {
          color: #2B2E31;
          font-weight: 600;
          display: flex;
          align-items: center;
          font-size: 18px;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .ask-page-body{
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    .conversations-container-by-reglement{
      overflow-y: auto;
      gap: 30px;
      width: 100%;
    }
    .start-new-conversation-button{
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background-color: rgba(47, 20, 229, 0.1) !important;
      color: #556581;
      font-size: 14px;
      font-weight: 600;
      border-radius: 10px;
      cursor: pointer;
      border: 2px solid #D9D9D9 !important;
    }
    .grouped-conversation-list{
      overflow-y: auto;
      gap: 20px;
      display: flex;
      flex-direction: column;
      max-height: 60vh;
    }
    .selected{
      border: 2px dashed #D9D9D9 !important;
      background-color: rgba(47, 20, 229, 0.1) !important;
    }
    .conversation-group-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 20px;
      border: 1px solid #D9D9D9;
      border-radius: 8px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      
      .reglement-header{
      color: #556581;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      .title{
        font-size: 16px;
        font-weight: 600;
      }
      .icon{
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      }
      .conversation:last-of-type{
        border-bottom: none;
      }
      .conversation{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        border-bottom: 1px solid #c9c8c8;
        padding-bottom: 5px;
        cursor: pointer;
        .title-section{
          display: flex;
          flex-direction: row;
          gap: 3px;
        }
        .title{
          font-size: 14px;
          font-weight: 500;
        }
        .title-selected{
          color: blue;
        }
        .icon-section{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .icon{
            width: 20px;
            height: 20px;
        }

        }
      }
    }
    .body-interactions-container{
      border-right: 1px solid #E5E5E5;
      flex: 3;
      height: 75vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      gap: 15px;
    .body-chats-container{
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      gap: 15px;
      width: 100%;
      .body-start-chat{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .start-chat-title{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 50px;
          .title{
            font-size: 24px;
            font-weight: 600;
            color: #2B2E31;
            margin-bottom: 50px;
          }

        }
        .suggest{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .suggest-card{
            flex: 1;
            max-width: 20%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 12px;
            background-color: white;
            font-size: 15px;
            border: 1px solid #AA9FF4;
            color: #707274;
          }
          .suggest-card:hover{
            cursor: pointer;
            background-color: #d6d1f4;
          }
        }
      }
      .body-chat{
        border-radius: 7px;
        padding: 10px;
        border: 1px solid #E5E5E5;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items: right;
        gap: 40px;
        
        .question-container{
          display: flex;
          flex-direction: column;
          justify-content: right;
          align-items: right;
          max-width: 100%;
          position: relative;
          .question-wrapper{
            justify-content: right;
            align-items: right;
            display: flex;
            flex-direction: column;
            position: absolute;
            gap: 5px;
            right: 0;
              .added-file{
                text-align: right;
                justify-content: right;
                gap: 5px;
                display: flex;
                align-items: center;
              }
            .question{
              background-color: #EAE8FC;
              padding: 10px;
              border-radius: 10px 10px 0 10px;
              
              }
          }
          
        }
        .answer{
          margin-top: 60px;
          padding: 10x;
        }
      }
    }
    .body-interactions-input-container{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      min-height: 50px;
      max-height: 230px;
      border-radius: 35px;
      padding: 10px;
      background-color: rgb(239, 238, 243);
      .query-input:focus{
        outline: none;
      }
      .add-file{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
      .input-section{
        flex: 1;
        display: flex;
        flex-direction:column;
        gap: 5px;
        justify-content: left;
        align-items: left;
        .added-file{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          margin-left: 20px;
        }
        .query-input{
          background-color: transparent;
          border: none;
          border-radius: 40px;
          padding: 10px;
          min-height: 20px;
          width: 100%;
          margin-left: 20px;
        }
      }
      .button-section{
        
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
        .ask-btn{
          background-color: transparent;
          border: none;
          box-shadow: none;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 5px;
          .icon{
            color: white;
            width: 30px;
            height: 30px;
          }
        }
      }
      .current-file{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 5px;
      }
      .ask-input{
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        flex: 9;
      }
      .ask-button{
        flex: 1;
        border-radius: 20px;
        color: white;
      }
    }
  }
    .body-conversations-container{
      flex: 2;
      padding: 10px;
      height: 100vh;

    }
  }
}
.document-docx-viewer iframe,
.document-pdf-viewer iframe {
  width: 100%;
  height: 100vh; /* Makes it take the full viewport height */
  border: none;
}
.document-docx-viewer {
  width: 100%;
  overflow: auto;
  min-width: 650px;
}
.document-pdf-viewer-preview iframe{
  width: 100%;
  height: 50vh; /* Makes it take the full viewport height */
  border: none;
}
.document-docx-viewer-preview iframe{
  width: 100%;
  height: 50vh; /* Makes it take the full viewport height */
  border: none;
}
.document-page-text-section{
  flex: 7;
  padding-left: 22px;
  padding-top: 5px;
  .contract-name{
      font-size: 20px;
  }
}
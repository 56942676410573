.btn-create-project {
    box-shadow: none !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    color: white;
    background-color: #2f14e5;
    border-radius: 10px;
    border: none;
    padding: 7px;
}
.projects-table {
    .actions {
      position: relative;
      .ai-icon{
        width: 30px;
        height: 30px;
      }
      .popup {
        position: absolute;
        width: 100px;
        background-color: white;
        z-index: 50;
        
        .contract-card-actions-modal {
          border: 1px solid#1c1d2212;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 80%;
          right: 40px;
          padding: 8px;
          background-color: white;
          width: fit-content;
          left: -30px;
        }
      
        .contract-card-actions-modal ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
      
        .contract-card-actions-modal li {
          cursor: pointer;
          padding: 5px;
          width: 100%;
          transition: background-color 0.3s ease;
        }
      
        .contract-card-actions-modal li:hover {
          background-color: #f2f2f2;
        }
  
        li:hover {
          background-color: #F5F5F5;
          cursor: pointer;
        }
      }
  
      .plus-icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;
        border: 1px solid gray;
        box-shadow: none;
        background-color: white;
      }
  
      .plus-icon:hover {
        background-color: #F5F5F5;
      }
    }
  
    .plus-icon:hover {
      background-color: #F5F5F5;
    }
    width: 100%;
    border-collapse: collapse; 

    th,
    td {
      word-wrap: break-word;
      white-space: normal;
      padding: 12px;
      border: 1px solid #ddd; 
      text-align: left;
    }
    td {
      overflow-wrap: break-word;
          white-space: normal;
    }
    .title-table-item div {
      overflow-wrap: break-word;
      word-break: break-word;
      white-space: normal;
      max-width: 100px; 
    }

    th {
      background-color: #f4f4f4;
      font-weight: bold;
      color: #333;
      border-bottom: 2px solid #ddd;
    }

    tbody tr {
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f9f9f9;
      }
      cursor: pointer;
    }

    .text-right {
      text-align: right; 
    }
    td:first-child>div {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        width: 25px;
        height: 25px;
      }
    }
    .status {
    background-color: #F5F6F6;
    color: #484A4D;
    border-radius: 7px;
    font-weight: 600;
    padding: 2%;
    text-align: center;
    max-width: -moz-fit-content;
    max-width: fit-content;
    }
    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  thead {
    position: sticky;
    top: 0;
    z-index: 1
  }

  tbody>div {
    max-height: 400px;
    overflow-y: auto; 
    display: block;
    width: 100%;
  }
  }
.search-bar{
    position: relative;
    .search-icon{
        position: absolute;
        left: 10px;
    }
}
.projects-space {
    position: relative;
    border: 2px dashed var(--neutral-light);
    border-radius: 8px;
    margin-top: 10px;
    min-height: 70vh;
    border-radius: 15px;
    max-width: 400px !important;
}

.projects-space-title {
    font-family: "Hanken Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 20.7436px;
    color: var(--neutral-medium);
}

.project-group {
    display: flex;
    flex-direction: column;

    .project-card {
        position: relative;
        width: 300px;
        height: 250px;
        margin-bottom: 8%;

        .layer-card-1,
        .layer-card-2,
        .layer-card-3 {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            border: 1px solid #E3EBF6;
        }

        .layer-card-1 {
            background-color: white;
            z-index: 1;
            left: 25px;
            bottom: 25px;
        }

        .layer-card-2 {
            background-color: white;
            z-index: 2;
            left: 12.5px;
            bottom: 12.5px;
        }

        .layer-card-3 {
            background-color: white;
            z-index: 3;
            min-height: 250px;
            height: fit-content;
        }

        .content {
            padding-left: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
            height: 100%;
            padding-right: 10px;
        }

        .project-subsection {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding-right: 10px;
        }

        .project-id {
            font-weight: 500;
            font-size: 17px;
            color: var(--neutral-light);
        }

        .project-beneficial {
            display: block;
            max-width: 35px;
            max-height: 35px;
            border-radius: 50%;
            border: 1px solid #E5E9F2;
        }


        .see-project {
            color: #2F14E5;
            border: none !important;
            box-shadow: none !important;
            background-color: white;
        }

    }
}
.project-level-name {
    background-color: #EEE3FD;
    width: fit-content;
    padding: 5px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: bold;
}
.project-name {
    font-weight: 900;
    font-size: 20px;
    color: var(--neutral-dark);
    letter-spacing: -0.02em;
}

.project-group-container {
    margin-top: 30px;
    p{
        font-weight: 700;
        font-size: 13px;
        color: var(--neutral-dark);
        max-width: 832px;
    }
}

.projects-per-level {
    min-height: 300px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}
.search-bar{
    position: relative !important;
    width:300px !important;
}
.search-project{
    right:0% !important;
    left: 89% !important;
    top:53% !important
}
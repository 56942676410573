.ressource-badge {
  margin-top: 5px;
  padding: 6px 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: white;
}

.ressource-badge:hover {
  background-color: #f5f3f3; 
  cursor: pointer;
}




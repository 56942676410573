.reglement-page {
  .section-title{
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
  .status-active{
    background-color: #e5f9d5;
    border: 1px solid #d6f6bc;
    color: #484A4D;
    margin: 5px 30px;
    padding: 7px 17px;
    border-radius: 10px;
    font-weight: 600;
  }
  .status-inactive{
    background-color: #E5E5E5;
    color: WHITE;
    margin: 5px 30px;
    padding: 7px 17px;
    border-radius: 10px;
    font-weight: 600;
  }
  .reglement-page-header {
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;

    .left-section {
      margin-left: 30px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
      .name-section{
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        .edit-icon{
          cursor: pointer;
          width: 30px;
          height: 30px;
          border: 1px solid #2F14E5;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .edit-input{
          width: 300px;
          height: 30px;
          border: 1px solid #2F14E5;
          border-radius: 4px;
          padding: 5px;
          color: #2B2E31;
          font-weight: 600;
        }
      }
      .close {
        border-right: 1px solid #E5E5E5;
        padding-right: 15px;
      }

      .title {
        display: flex;
        flex-direction: row;
        gap: 10px;

        
        .name-section{
          .name {
          color: #2B2E31;
          font-weight: 600;
          display: flex;
          align-items: center;
          font-size: 18px;
        }
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .right-section {}
  }

  .reglement-page-body {
    .edit-reglement{
      width: 99%;
      border: 1px solid #E5E5E5;
      border-radius: 8px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      padding: 30px;
      margin-right: 10px;
      position: relative;
      margin-bottom: 20px;
      .update-loading {
        width: 100%;
        height: 100%;
        background-color: #0e006b5d;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 8px;
    }
      .edit-icon{
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border: 1px solid #2F14E5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .suggestions{
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-top: 20px;
      }
      .suggest-add{
        cursor: pointer;
        background-color: #d6d1f4;
        flex: 1;
        max-width: 20%;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 12px;
        font-size: 15px;
        border: 1px solid #AA9FF4;
        color: #707274;
      }
      .suggest-card{
        flex: 1;
        max-width: 20%;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 12px;
        background-color: white;
        font-size: 15px;
        border: 1px solid #AA9FF4;
        color: #707274;
        position: relative;
      }
      .delete-suggest-card{
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        color: red;
        border: 1px solid red;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .hyper-params-section{
        display: flex;
        flex-direction: row;
        gap: 50px;
        margin-top: 10px;
      }
      .hyper-params-col{
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .hyper-param-container{
        height: 120px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        .input-field{
          width: 100% !important;
        }
        .input-label{
          color: black !important;
        }
      }
      .compression-params-header{
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        margin-top: 20px;
        .title{
          font-size: 20px;
        font-weight: bold;
        }
        .form-check{
          margin: 0;
    
        }
      }
    }
    .reglement-files-list {
      padding: 30px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex-wrap: wrap;
      .ressource-card {
        width: 220px;
        height: 170px;
        background-color: #EAE8FC;
        border: 1px solid #2F14E5;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
        overflow: hidden;
        position: relative;
        .delete-icon{
          position: absolute;
          top: 15px;
          right: 15px;
          cursor: pointer;
          width: 15px;
          height: 15px;
        }
        .title {
          color: #2F14E5;
          font-weight: 600;
          text-align: center;
          text-overflow: ellipsis;
          max-width: 200px;
          word-break: break-all;
        }
      }

      .add-card {
        cursor: pointer;
        width: 220px;
        height: 170px;
        background-color: #FAFAFA;
        border: 1px solid #ECECED;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .title {
          color: #707274;
          font-weight: 600;
          text-align: center;
          text-overflow: ellipsis;
        }
      }
    }
    .actions{
      width: 100%;
      display: flex;
      flex-direction: row;
      .disabled{
        cursor: not-allowed !important;
        background-color: #E5E5E5 !important;
        color: white !important;
      }
       .commit-button{
        cursor: pointer;
        padding: 12px, 16px, 12px, 16px !important;
        background-color: #23C286;
        color: white;
        font-weight: 600;
        width: 130px;
        height: 60px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    }
   
  }
}
.main-function {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure content and buttons are spaced */
    padding: 16px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    position: relative;
    min-height: 220px; /* Ensure minimum height */

}
.admin-main-functions{
    width: 100% !important;
    height: 100% !important;
    min-height:200px !important ;
    position: relative;

}



.main-function h2 {
    font-weight: bolder;
    color: var(--secondary-color) !important;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 20px;
}
.main-function h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px; /* Adjust the width (length) of the border-bottom */
    height: 7px;
    background-color: var(--secondary-color-medium);
}
.main-function .btn {
    width: 100%;
    height: 50px;
    background-color: var(--primary-color-medium);
    color: white;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02), 0px 13px 8px rgba(51, 18, 239, 0.07),
        0px 6px 6px rgba(51, 18, 239, 0.12), 0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
    border-radius: 10px;
    border: none;
    position: absolute;
    bottom: 2%;
    width: 92% !important;

}
.main-function .btn-pilot {
    width: 100%;
    height: 50px;
    background-color: var(--primary-color-medium);
    color: white;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02), 0px 13px 8px rgba(51, 18, 239, 0.07),
        0px 6px 6px rgba(51, 18, 239, 0.12), 0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
    border-radius: 10px;
    margin-bottom: 20px;

}
.main-function .btn-outline-pilot {
    width: 100%;
    height: 50px;
    background-color: white;
    color: var(--primary-color-medium);
    font-size: 14px;
    font-weight: 700;
    border: 2px solid var(--primary-color-medium);
    
    box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02), 0px 13px 8px rgba(51, 18, 239, 0.07),
        0px 6px 6px rgba(51, 18, 239, 0.12), 0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
    border-radius: 10px;

}
.btn-action-admin{
    left: 5%
}
.card-title-icon{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.document-page-container{
    display: flex;
    flex-direction: row;

.header-useCaseQA{
    font-weight: 900;
    font-size: 23px;
    color: var(--neutral-medium);
}
.document-page-text-section{
    flex: 7;
    padding-left: 22px;
    padding-top: 5px;
    .contract-name{
        font-size: 20px;
    }
    display: flex;
    flex-direction: column;
}
.document-page-use-case-section{
    flex: 3;
    border-left: 1px solid #E5E9F2;
}
.ai-text-container {
    overflow-y: auto;
    background-color: var(--secondary-color-lighter);
    border: 2px dashed #888;
    transition: all 0.8s ease;
    opacity: 0.8;
    padding: 20px;
    max-height: 80vh !important;
    border-radius: 12px;
    white-space: pre-line;
    width: 100%;
    display: flex;
    margin: auto;
    flex: 1;
}

.ai-text {
    font-weight: bold;
    font-size: 14px;
}
.ai-text {
    font-weight: bold;
    font-size: 14px;
}
.variable-input-container{
    padding: 10px;
    max-height: 79vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 20px;
}
::-webkit-scrollbar {
    width: 6px !important;
}

::-webkit-scrollbar-thumb {
    background-color: #88888864 !important;
    border-radius: 6px !important;
    background-clip: content-box; 
}

::-webkit-scrollbar-track {
    background-color: #f0f0f0 !important;
}
 ::-moz-scrollbar {
    width: 6px !important;
}

::-moz-scrollbar-track {
    background: #f0f0f0 !important;
}

::-moz-scrollbar-thumb {
    background: #888 !important;
    border-radius: 6px !important;
}
}
.chat-document-button {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: white;
    color: var(--neutral-medium);
    font-size: 14px;
    font-weight: 700;
    border: 2px solid var(--neutral-light);
    box-shadow: none;
    text-align: start;
  }
  .chat-document-button:hover {
    background-color: var(--neutral-extra-light);
}
.icon-doc {
    width: 24px;
    height: 24px;
    margin-left: 5px;
    margin-right: 5px;
}  
.ressources-table {
  .actions {
    position: relative;
    .ai-icon {
      width: 30px;
      height: 30px;
    }
    .popup {
      position: absolute;
      width: 100px;
      background-color: white;
      z-index: 50;

      .ressource-card-actions-modal {
        border: 1px solid#1c1d2212;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 80%;
        right: 40px;
        padding: 8px;
        background-color: white;
        width: fit-content !important;
        left: -30px !important;
      }

      .ressource-card-actions-modal ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      .ressource-card-actions-modal li {
        cursor: pointer;
        padding: 5px;
        width: 100%;
        transition: background-color 0.3s ease;
      }

      .ressource-card-actions-modal li:hover {
        background-color: #f2f2f2;
      }

      li:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }
    }

    .plus-icon {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: gray;
      border: 1px solid gray;
      box-shadow: none;
      background-color: white;
    }

    .plus-icon:hover {
      background-color: #f5f5f5;
    }
  }

  .plus-icon:hover {
    background-color: #f5f5f5;
  }
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    word-wrap: break-word;
    white-space: normal;
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  td {
    overflow-wrap: break-word;
    white-space: normal;
  }
  .title-table-item div {
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    max-width: 100px;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #333;
    border-bottom: 2px solid #ddd;
  }

  tbody tr {
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f9f9f9;
    }
    cursor: pointer;
  }

  .text-right {
    text-align: right;
  }
  td:first-child > div {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
    }
  }
  .status {
    background-color: #f5f6f6;
    color: #484a4d;
    border-radius: 7px;
    font-weight: 600;
    padding: 2%;
    text-align: center;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody > div {
    max-height: 400px;
    overflow-y: auto;
    display: block;
    width: 100%;
  }
}

.group-ressources-page {
  width: calc(100vw - 300px);

  .ressources h1 {
    font-weight: 700;
    color: var(--neutral-darker);
    font-size: 16px;
  }

  .search-icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input[type="text"] {
    padding-left: 30px;
    text-decoration: none;
    /* Adjust the left padding to accommodate the search icon */
  }

  .search-input {
    height: 48px;
    width: 408px;
    border-radius: 8px;
    border: 1px solid var(--neutral-light);
  }

  .search-zone {
    width: 100%;
    display: flex;
    margin: 20px;

    .search-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .search {
        position: relative;
        display: flex;
        border-radius: 8px !important;

        .search-input {
          border-radius: 8px;
        }
      }
    }

    .ressources-filters {
      flex: 1;
      display: flex;
      padding-top: 10px;

      .filter-item {
        width: 250px;
        display: flex;
        align-items: center;
        gap: 10px;

        .custom-combobox-main {
          padding: 5px;
          background-color: white;

          .custom-combobox-container {
            margin: auto !important;
            border: 1px solid var(--neutral-light);
            border-radius: 8px !important;
            padding: 3px;

            .custom-combobox-selected {
              overflow: hidden;
            }
          }

          .custom-combobox-selected {
            color: #707274 !important;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .filter-type-item {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }
      }
    }
  }

  .filter > div:first-child {
    font-weight: 700;
    font-size: 12px;
    color: var(--neutral-medium);
  }

  .filter > div:nth-child(2) {
    color: var(--primary-color-darker);
    font-weight: 400;
    font-size: 14px;
  }

  .ressource-space {
    border: 2px dashed var(--neutral-light);
    min-height: 55vh !important;
    border-radius: 15px;
    margin-bottom: 10px;
    width: 30%;
  }

  .ressource-space-title {
    font-family: "Hanken Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 20.7436px;
    color: var(--neutral-medium);
  }

  .ressources-per-level {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ressources-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
  }

  .ressource-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .ressources {
    width: 100%;

    h1 {
      font-weight: 700;
      color: var(--neutral-darker);
      font-size: 16px;
    }

    .search-bar {
      position: relative;
      display: flex;
    }

    .search-icon {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    input[type="text"] {
      padding-left: 30px;
      text-decoration: none;
      /* Adjust the left padding to accommodate the search icon */
    }

    .search-input {
      height: 48px;
      width: 408px;
      border-radius: 8px;
      border: 1px solid var(--neutral-light);
    }

    .search-zone {
      width: 641px;
    }

    .filter > div:first-child {
      font-weight: 700;
      font-size: 12px;
      color: var(--neutral-medium);
    }

    .filter > div:nth-child(2) {
      color: var(--primary-color-darker);
      font-weight: 400;
      font-size: 14px;
    }

    .ressources-space {
      border: 2px dashed var(--neutral-light);
      min-height: 55vh !important;
      border-radius: 15px;
      margin-bottom: 10px;
      width: 30%;
    }

    .ressources-space-title {
      font-family: "Hanken Grotesk";
      font-style: normal;
      font-weight: 500;
      font-size: 20.7436px;
      color: var(--neutral-medium);
    }
  }

  .group-ressources-list {
  }

  .ressources-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
  }

  .ressources-space {
    width: 440px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 3px dashed #ececed;
    border-radius: 14px;
  }

  .ressources-per-level {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}

.contract-container {
    width: 100%;

    .contract-name {
        font-weight: 900;
        font-size: 20px;
        color: var(--neutral-dark);
        letter-spacing: -0.02em;
    }

    .contract-loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .h4 {
        font-weight: 900;
        font-size: 23px;
        color: var(--neutral-medium);
    }

    .h5 {
        font-weight: 700;
        font-size: 15px;
    }

    .h6 {
        font-weight: 500;
        font-size: 14px;
        color: var(--neutral-medium);
    }

    .p {
        font-weight: 400;
        font-size: 14px;
        color: var(--neutral-medium);
    }

    .edition-contract-page {
        display: flex;
        flex-direction: column;
        width: 100%;

    }

    .rotate {
        transform: rotate(-90deg) !important;
        transition: all 0.3s ease-in-out;
    }

    /*
    ------styling for edit contract page*/
    .overlay {
        position: absolute; 
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.592);
        z-index: 0; 
        pointer-events: none; 
      }
      
      .overlay-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        color: black;
        font-weight: bold;
        pointer-events: auto; 
      }
      
      
    .status-select {
        display: flex;
        flex-direction: column;
        margin: 20px;
        margin-top: -3px;
      
        .status-label {
          font-size: 1.2rem;
          font-weight: bold;
          color: #333;
          margin-bottom: 10px;
        }
      
        .select-wrapper {
          position: relative;
      
          .status-dropdown {
            width: 100%;
            padding: 10px 40px 10px 10px;
            border: 2px solid #007bff;
            border-radius: 15px;
            font-size: 1rem;
            color: #555;
            background-color: #fff;
            appearance: none; 
            cursor: pointer;
            transition: border-color 0.3s;
      
            &:hover {
              border-color: #0056b3; 
            }
      
            &:focus {
              outline: none;
              border-color: #0056b3; 
            }
      
            &.disabled-option {
              color: gray; 
              background-color: #f8f9fa; 
              cursor: not-allowed;
            }
      
            &.enabled-option {
              color: black; 
              cursor: pointer;
            }
          }
      
          .icon-success {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: #28a745; 
            font-size: 1.5rem;
            visibility: hidden; 
          }
      
          .icon-error {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: #dc3545;
            font-size: 1.5rem;
            visibility: hidden; 
          }
        }
      
        .status-dropdown:focus + .icon-success {
          visibility: visible; 
        }
      
        .status-dropdown:focus + .icon-error {
          visibility: visible; 
        }
      }
      
      
    .edit-contract {
        .clause-list-container {
            height: 80vh;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .variable-input-container {
            height: 80vh;
            overflow-y: auto;
        }

        .clause-list-container::-webkit-scrollbar,
        .variable-input-container::-webkit-scrollbar {
            width: 8px;
        }

        .clause-list-container::-webkit-scrollbar-track,
        .variable-input-container::-webkit-scrollbar-track {
            background-color: #f1f1f1 !important;
            border-radius: 4px;
        }

        .clause-list-container::-webkit-scrollbar-thumb,
        .variable-input-container::-webkit-scrollbar-thumb {
            background-color: rgb(204, 202, 205) !important;
            border-radius: 4px;
        }

        .clause-list-container::-webkit-scrollbar-thumb:hover,
        .variable-input-container::-webkit-scrollbar-thumb:hover {
            background-color: var(--neutral-medium);
        }

        .completion-low {
            background-color: var(--ai-error-medium);
            padding: 2px 5px;
            border-radius: 5px;
            color: var(--ai-error-darkest);
        }

        .completion-medium {
            background-color: var(--ai-warning-medium);
            padding: 2px 5px;
            border-radius: 5px;
            color: var(--ai-warning-darkest);
        }

        .completion-high {
            background-color: var(--ai-success-medium);
            padding: 2px 5px;
            border-radius: 5px;
            color: var(--ai-success-darkest);
        }

        .contract-header {
            border-bottom: 1px solid #E5E9F2;
            margin-bottom: 3px;
            max-height: 35px;
        }

        .contract-tools {
            display: flex;
            gap: 20px;
        }

        .contract-name {
            white-space: nowrap;
        }

        .contract-clause {
            border-left: 1px solid #E5E9F2;
        }

        .contract-clause-header {
            margin-bottom: 3px;
            border-bottom: 1px solid #E5E9F2;
        }

        .contract-clause-name {
            white-space: nowrap;
        }
    }

    /*
    --------clause styling
    */
    .edit-contract-clause {
        border-radius: 12px;
        box-shadow: 1px 1px 1px 1px #e5e9f2, -1px -1px 1px 1px #e5e9f2;

        .selected {
            background-color: var(--secondary-color-lighter);
            border: 2px dashed #888;
            transition: all 0.8s ease;
            opacity: 0.9;
            padding: 10px;
            max-height: 79vh;
            overflow-y: auto;
            overflow-x: hidden;
        }

        /* Webkit (Chrome, Safari) */
        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 6px;
        }

        ::-webkit-scrollbar-track {
            background-color: #f0f0f0;
        }

        /* Firefox */
        ::-moz-scrollbar {
            width: 6px;
        }

        ::-moz-scrollbar-track {
            background: #f0f0f0;
        }

        ::-moz-scrollbar-thumb {
            background: #888;
            border-radius: 6px;
        }

        .selectable {
            cursor: pointer !important;
        }

        .fade-enter {
            opacity: 0.01;
        }

        .fade-enter.fade-enter-active {
            opacity: 1;
            transition: opacity 500ms ease-in;
        }

        .fade-exit {
            opacity: 1;
        }

        .fade-exit.fade-exit-active {
            opacity: 0.01;
            transition: opacity 500ms ease-in;
        }

        .scrollable-clause {
            max-height: 80vh;
            overflow-y: auto;
        }



        .edit-contract-clause-comment {
            font-size: 22px;
            color: #ffffff;
            margin-bottom: 10px;
            padding: 5px;
            max-height: 150px;
            margin-top: 10px;
        }

        .speech-bubble {
            position: relative;
            max-width: 20em;
            background-color: #7014e5;
            padding: 1.125em 1.5em;
            font-size: 12px;
            border-radius: 1rem;
            box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
        }

        .input-comment {
            background-color: #7014e5;
            padding: 3px;
            margin: 0;
            font-size: 12px;
            border: none;
            color: #ffffff;
        }

        .input-comment:focus {
            outline: none;
        }

        .scrollable-clause::-webkit-scrollbar {
            width: 8px;
        }

        .scrollable-clause::-webkit-scrollbar-track {
            background-color: #f1f1f1;
            border-radius: 4px;
        }

        .scrollable-clause::-webkit-scrollbar-thumb {
            background-color: var(--neutral-light);
            border-radius: 4px;
        }

        .scrollable-clause::-webkit-scrollbar-thumb:hover {
            background-color: var(--neutral-medium);
        }

        .edit-contract-clause-header-icons {
            height: 40px;
            cursor: pointer;
            // min-width: 100%;
            margin-left: 0% !important;
            float: right;
            right: 0;
        }

        .edit-contract-clause-header {
            font-size: 20px;
            color: #556581;
            display: flex;
            justify-content: space-between;

        }

        .edit-contract-clause-header-icon:first-of-type {
            margin-right: 13px;
        }

        .triangle {
            width: 0;
            height: 0;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-bottom: 13px solid #7014e5;
            /* You can set the color to your preference */
            position: absolute;
            top: -12px;
            left: 17px;
        }
        .edit-contract-clause-header-icons-arabic{
            min-width: 90px;
            position:relative;
            right: 88%;
            height: 40px;
        }

        .comment-text {
            max-height: 70px;
            overflow-y: auto;
            word-wrap: break-word;
        }
    }

    /*
      ----------clause input styling
      */
    .edit-contract-clause-input {
        border-radius: 12px;
        box-shadow: 1px 1px 1px 1px #e5e9f2, -1px -1px 1px 1px #e5e9f2;

        .edit-contract-clause-input-header {
            font-size: 16px;
            color: #556581;
        }

        .selected-input-container {
            border: 2px dashed var(--primary-color);
            transition: all 0.8s ease;
            opacity: 0.9;
            padding: 10px;
            overflow-y: auto;
            max-height: 79vh !important;
            overflow-x: hidden;
        }

        .variable-input {
            cursor: pointer;
        }

        .param-count-label {
            font-weight: 700;
            font-size: 12px;
            color: var(--primary-color-darker);
        }

        .input-group-title,
        .input-group-subtitle {
            font-weight: 700;
            color: var(--neutral-medium);
        }

        .input-group-title {
            font-size: 17px !important;
        }

        .input-group-subtitle {
            font-size: 14px !important;
        }


        .grey-border {
            border: 2px solid #acabba !important;
            border-radius: 4px;
        }

        .green-border {
            border: 2px solid var(--ai-success-medium) !important;
            border-radius: 4px;
        }

        .radio-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }

        .switch input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .switch label {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: #ccc;
            border-radius: 50%;
            transition: background-color 0.4s;
            cursor: pointer;
        }

        .switch input[type="checkbox"]:checked+label {
            background-color: var(--primary-color);
            border: 1px solid grey;
        }

        .switch label::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            background-color: white;
            border-radius: 50%;
            transition: background-color 0.4s;
        }

        .switch input[type="checkbox"]:checked+label::after {
            padding: 2px;
        }

        .ai-validate-button {
            font-weight: 600;
            font-size: 12px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
            transition: transform 0.1s ease-in-out;
        }

        .ai-validate-button:active {
            transform: scale(0.95);
        }

        .ai-response {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
        }

        .cursor-pointer {
            cursor: pointer !important;
        }

        .up-active {
            color: var(--ai-success-medium);
        }

        .down-active {
            color: var(--ai-error-medium);
        }

        .inactive {
            color: var(--primary-color);
        }
    }

    /*
    ---------edit contract input list styling
    */
    .variable-input {
        cursor: pointer;

        .selected-input-container {
            border: 2px dashed var(--primary-color);
            transition: all 0.8s ease;
            opacity: 0.9;
            padding: 10px;
        }

        .param-count-label {
            font-weight: 700;
            font-size: 12px;
            color: var(--primary-color-darker);
        }

        .input-group-title,
        .input-group-subtitle {
            font-weight: 700;
            color: var(--neutral-medium);
        }

        .input-group-title {
            font-size: 17px !important;
        }

        .input-group-subtitle {
            font-size: 14px !important;
        }

        .benificial-button {
            font-weight: 600;
            background-color: var(--primary-color-medium);
            color: white;
            padding: 7px;
        }

        .grey-border {
            border: 2px solid #acabba !important;
            border-radius: 4px;
        }

        .green-border {
            border: 2px solid var(--ai-success-medium) !important;
            border-radius: 4px;
        }

        .radio-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }

        .switch input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .switch label {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: #ccc;
            border-radius: 50%;
            transition: background-color 0.4s;
            cursor: pointer;
        }
        label {
            font-weight: 700;
            font-size: 12px;
            color: #2f14e5;
            font-style: normal;
        }

        .switch input[type="checkbox"]:checked+label {
            background-color: var(--primary-color);
            border: 1px solid grey;
        }

        .switch label::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            background-color: white;
            border-radius: 50%;
            transition: background-color 0.4s;
        }

        .switch input[type="checkbox"]:checked+label::after {
            padding: 2px;
        }

        .ai-validate-button {
            font-weight: 600;
            font-size: 12px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
            transition: transform 0.1s ease-in-out;
        }

        .ai-validate-button:active {
            transform: scale(0.95);
        }

        .ai-response {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
        }

        .cursor-pointer {
            cursor: pointer !important;
        }

        .up-active {
            color: var(--ai-success-medium);
        }

        .down-active {
            color: var(--ai-error-medium);
        }

        .inactive {
            color: var(--primary-color);
        }
    }

    /*
    -----parameter input styling
    */
    .parameter-input {

        /* update element input progress */
        .input-inprogress {
            border-radius: 800px;
            background: #fff4e7;
            padding: 2px 5px;
            font-weight: 400;
            font-size: 12px;
            height: 25px;
            min-width: 88.47px;
            text-align: center;
            color: #212529;
        }

        .boolean-group {
            gap: 35px !important;
            align-items: center !important;
        }

        /* change all input type text  */
        .input-inprogress+input {
            color: black !important;
            font-size: 12.9px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 19.64px !important;
            padding: 0% !important;
        }

        /* change all input type date */
        .react-datepicker__input-container>input {
            padding: 0% !important;
            font-size: 12.9px !important;
        }

        /* change all input type file */

        .input-type-file {
            padding: 0% !important;
            font-size: 12.9px !important;
            margin: 0% !important;

        }

        /* update element input done */
        .input-done {
            padding: 2px 5px;
            min-width: 88px;
            font-weight: 700;
            font-size: 12px;
            width: 88.47px;
            height: 25px;
            color: #0fca7a;
            text-align: center;
            letter-spacing: 0.1px;
            border-radius: 800px;
            border: 1px solid #e5f9f0;
            background: #E5F9F0;
        }

        /* update form check label */
        .form-check-label {
            color: #1f2b3a;
            font-size: 11.9px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.64px;
        }

        /* update radio button */
        .form-check-input {
            background-color: #ede5fc;
            border-color: #ede5fc;
        }

        .form-check-input:checked[type="radio"] {
            background-color: #ede5fc;
            border-color: #ede5fc;
            background-image: url("https://www.publicdomainpictures.net/pictures/310000/nahled/blue-circle.png");
            background-size: 9.5px 9.5px;
            background-position: 2.5px;
        }
        .checked-input{
            background-image: url("https://www.publicdomainpictures.net/pictures/310000/nahled/blue-circle.png");
            background-size: 9.5px 9.5px;
            background-position: 2.5px; 
        }
        .boolean-check {
            width: 15px;
            height: 15px;
        }

        .boolean-check:checked[type="radio"] {
            background-size: 8.5px 8.5px !important;
            background-position: 2.5px !important;
        }

        .input-error {
            background-color: var(--ai-error-clear);
            color: var(--ai-success-darkest);
            font-weight: 700;
            font-size: 12px;
            height: 25px;
            min-width: 70px;
            text-align: center;
        }

        /* update boolean type into checkbox */
        .segmented-control {
            display: flex;
        }

        .segment {
            cursor: pointer;
            padding: 10px;
            border: 1px solid #e5e9f2;
            flex: 1;
            min-width: 40px;
            position: relative;
            background-color: #e5e9f2;
        }

        .segment.active {
            background-color: #7014e5;
        }

        .segment.segment.active::after {
            background: none !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: #000 !important;
        }

    }
    .add-list-beneficials{
        margin-left: 5%;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
.list-beneficial {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    max-width: 90%;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    max-height: 200px;
    overflow-y: auto; 
    margin-top: 8px;
  }
  
  .list-beneficial .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    background-color: #ffffff;
    border-radius: 6px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .list-beneficial .item:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .list-beneficial .item {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
  }
  
  .list-beneficial .item>div>div:hover {
    text-decoration: underline;
  }
  .list-beneficial .beneficial-name{
    width: min-content;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;  
  }
  .list-beneficial .item svg {
    margin-left: 0.5rem;
    color: #e5142f;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .list-beneficial .item svg:hover {
    color: #b31224;
  }
  .list-beneficial .type-tag {
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    background-color: rgba(85, 85, 102, 0.521);
    color: #fff;
    max-height:fit-content;
    height: fit-content;
    width: fit-content;
  }
  .type-tag:hover{
    text-decoration: none;
  }
  
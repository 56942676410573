.generate-ai-portal{
  width: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  cursor: default !important;
  border: 1px solid #eaefed;
  z-index: 1000;
  border: 1px solid #2F14E5;
}
.generate-ai-portal-header{
  height: 22px;
  position: relative;

  .close-dialog{
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    border-radius: 50%;
    color: #726b6b;
    border: 1px solid #2F14E5 !important;
    width: 18px;
    height: 18px;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close-dialog:hover{
  color: #2F14E5;
  background-color: #eee3fd;
}
}

.generate-ai-portal-footer{
  border-top: 1px solid #eaefed;
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .generate-ai-portal-button{
    margin: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    min-height: 45px;
  }
  .reset-ai-portal-button{
    margin: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    height: 45px;
  }
  .disabled{
    background-color: #f0f0f0;
    color: #b3b3b3;
    cursor: not-allowed;
  }
}

.generate-ai-portal-content{
  .generate-ai-portal-content-checkbox{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: 5px;
    cursor: pointer;
    padding-left: 3px;
  }
  .generate-ai-portal-content-checkbox:hover{
    background-color: #eee3fd;
  }
  .generate-ai-portal-label{
    font-size: 12px;
    font-weight: 700;
    margin: 5px 0;
    cursor: pointer;
  }
  .generate-ai-portal-errors{
    color: #b60f0f;
    font-size: 11px;
    font-weight: 600;
    margin: 5px 0;
  }
  .generate-ai-portal-add-comments{
    display: flex;
    flex-direction: column;
    margin: 5px;
    border-top: 1px solid #eaefed;
    .generate-ai-portal-add-input{
      height: 40px;
      font-size: 12px;
      font-weight: 500;
      padding: 2px !important;
      max-height: 60px;
      min-height: 20px;
    }
    .generate-ai-portal-add-label{
      font-size: 12px;
    font-weight: 700;
      padding-left: 3px;
    }
  }
}